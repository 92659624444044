.bg-contacthalf {
    background: rgb(1,32,76);
    background-image: linear-gradient( 90deg, #011422  50%, #032137 50% );
}

.Contactform_body {
    background: #032137;
    padding: 70px 0px 70px 100px;
    border-radius: 60px 0px 0px 60px;
    margin-top: 40px;
    position: relative;
    margin-bottom: 100px;
    &:before {
        content: "";
        width: 395px;
        height: 123px;
        background-image: url(../../Static/Contact_icon.svg);
        position: absolute;
        right: -40px;
        z-index: 9999;
        top: -18px;
    }
    &:after {
        content: "";
        width: 640px;
        height: 170px;
        background-image: url(../../Static/Contact_icon2.svg);
        position: absolute;
        left: 170px;
        z-index: 9999;
    }
    h3 {
        font-family: 'Archivo';
        font-weight: 800;
        font-size: 36px;
        line-height: 150%;
        letter-spacing: 0.04em;
        color: #FFFFFF;
    }
    input.form-control {
        background-color: transparent;
        border: 0px none;
        border-bottom: 1px solid #0F446A;
        border-radius: 0px;
        padding: 0px;
        height: 50px;
        font-family: 'Archivo';
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.04em;
        color: #fff;
    }
    textarea.form-control {
        background-color: transparent;
        border: 0px none;
        border-bottom: 1px solid #0F446A;
        border-radius: 0px;
        padding: 0px;
        margin-top: 15px;
        color: #fff;
        resize: none;
    }
    .form-check {
        margin-top: 30px;
        margin-bottom: 30px;
        label {
            font-family: 'Archivo';
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.04em;
            color: rgba(255, 255, 255, 0.84);
        }
        input {
            background-color: transparent;
            border: 2px solid #0F446A;
            width: 24px;
            height: 24px;
            position: relative;
            top: -5px;
            margin-right: 10px;
        }
        input:focus {
            outline:none !important;
            box-shadow: none !important;

        }
    }
    .btn-orange.btn.btn-primary {
        z-index: 99999;
        position: relative;
    }
    .errormessase {
        color: #ff0000eb;
        font-size: 12px;
        margin-top: 5px;
    }
    .Thankyoumessage h4 {
        font-size: 18px;
        margin-top: 26px;
        background: linear-gradient(61deg, #FAB013 5.55%, #FF2E00 30.36%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.Contactus {
    .centerheading_main {
        max-width: 660px;
        margin: 40px auto 0px;
    }
}

input:focus, 
textarea:focus {
    box-shadow: none !important;
}

@media (max-width:767px) {
    
    .bg-contacthalf {
        background: #011422;
        background-image: linear-gradient(90deg, #011422 50%, #011422 50%);
        .Contactform_body {
            padding: 40px 30px 40px 30px;
            border-radius: 30px;
            margin-top: 40px;
            position: relative;
            margin-bottom: 0px;
            &:after {
                left: 0px;
                position: absolute;
                left: 30px;
                max-width: 300px;
                width: 100%;
                background-size: 300px;
                background-repeat: no-repeat;
                bottom: -108px;
            }
            &:before {
                z-index: 9999;
                top: -11px;
                left: auto;
                right: 0px;
                max-width: 300px;
                background-size: 200px;
                background-repeat: no-repeat;
                height: 70px;
            }
        }
    }
}