.PricingBannerTop {
    color: #fff;
    margin-top: 60px;
    position: relative;
    &:before {
        margin: 0px auto;
        content: "";
        filter: blur(100px);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        z-index: 0;
        background: rgba(72, 110, 136, 0.5);
    }
    img {
        width: 100%;
    }
    object {
        z-index: 9;
        position: relative;
    }
}

.pricingpage .centerheading_main p {
    max-width: 660px;
    margin: 15px auto 40px;
}
.pricingpage .btndetails {
    padding: 20px 0px 0px 0px;
    margin-bottom: 100px;
}


.Choose_Planlist_main {
    padding-top: 100px;
    padding-bottom: 40px;
    position: relative;
    &:before {
        content: "";
        left: -155px;
        background-image: url(../../Static/aboubgRight2.svg);
        top: 100px;
        background-repeat: no-repeat;
        background-position: 0% 0%;
        z-index:0;
        position: absolute;
        bottom: 0px;
        margin: 0px auto;
        opacity: 0.05;
        width: 450px;
        height: 500px;
        background-size: contain;
    }
}

.planListing {
    color: #fff;
    z-index: 99;
    position: relative;
    .pricelistHeading {
        height: 70px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        h3 {
            margin: 0px 0px 15px 0px;
            font-family: 'Archivo';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            color: #FFFFFF;
        }
    }
    .pricelistAmout {
        height: 97px;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        h2 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            color: rgba(255, 255, 255, 0.84);
            
        }
    }
    .pricelistCheckbox {
        margin: 40px 0px;
        p {
            line-height: 33px;
        }
        
    }
}

.PriceListmain {
    color: #fff;
    background-color: #032137;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 40px;
    .pricelistHeading {
        background: linear-gradient(76.83deg, #FAB013 10.02%, #FF2E00 90.82%);
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        clip-path: polygon(100% 0, 100% 70%, 52% 100%, 0 70%, 0 0);
        margin-bottom: 20px;
        h3 {
            margin: 0px 0px 15px 0px;
            font-family: 'Archivo';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            color: #FFFFFF;
        }
    }
    .pricelistAmout {
        h2 {
            font-family: 'Archivo';
            font-weight: 400;
            font-size: 64px;
            line-height: 70px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 0px;
        }
        p {
            color: rgba(255, 255, 255, 0.54);
            font-weight: 400;
            font-size: 18px;
        }
    }
    .pricelistCheckbox {
        margin: 40px 0px;
        p {
            font-size: 18px;
            margin-bottom: 10px;
            svg {
                color: #27AE60;
            }
        }
    }
}

.PricingList_Free {
    .PriceListmain {
        .pricelistHeading {
            background: #FAB013;
        }
        .pricelistButton {
            button.btn_orange_border.btn.btn-primary {
                border: 1px solid #FAB013;
                color: #FAB013;
                -webkit-background-clip: inherit;
                -webkit-text-fill-color: initial;
                background: transparent;
            }
            button.btn_orange_border.btn.btn-primary:hover {
                color: #ff2e00;
                border: 1px solid #ff2e00;
            }

        }
    }
    
}
.PricingList_Standard {
    .PriceListmain {
        .pricelistHeading {
            background: #FF2F01;
        }
        .pricelistButton {
            button.btn_orange_border.btn.btn-primary {
                border: 1px solid #FF2F01;
                color: #FF2F01;
                -webkit-background-clip: inherit;
                -webkit-text-fill-color: initial;
                background: transparent;
            }
            button.btn_orange_border.btn.btn-primary:hover {
                color: #FAB013;
                border: 1px solid #FAB013;
            }
        }
    }
}
.PricingList_Advanced {
    .PriceListmain {
        .pricelistButton {
            button.btn_orange_border.btn.btn-primary:hover {
                background: linear-gradient(80.04deg, #FF2E00 5.55%, #FAB013 90.36%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                border: 1px solid #FF2F01;
            }
        }
    }
}

.priceinglistname_mobile {
    font-size: 18px;
    svg {
        margin-right: 0px;
        fill: #fe3701;
    }
}
.priceinglistname_mobile {
    display: none;
}

@media (max-width:990px) {
    .PriceListmain {
        margin-bottom: 30px;
    }
    .Choose_Planlist_main {
        padding-bottom: 0px;
        &:before {
            display: none;
        }
    }
    .Choose_Planlist_main ~ .btndetails {
        padding: 0px 0px 0px 0px;
        margin-bottom: 0px;
        .btn-orange {
            font-size: 18px;
        }
    }
    .priceinglistname_mobile {
        display: inline-block;
    }
    .mobileview_list {
        display: none;
    }
}