.bg_blue_full {
    background-color: #011422;
}
.margintop {
    margin-top: 70px;
    margin-bottom: 75px;
    .container-fluid {
        padding-left: 0px;
    }
}

.bgRightLogo {
    position: relative;
    &:before {
        content: "";
        right: 0px;
        width: 100%;
        height: 100%;
        background-image: url(../../Static/aboubgRight2.svg);
        background-size: contain;
        top: 0px;
        background-repeat: no-repeat;
        background-position: 120% 0%;
        z-index: 999999999999;
        position: absolute;
        bottom: 0px;
        margin: 0px auto;
        opacity: 0.05;
        z-index: 0;
    }
}

.leftside_contant_about {
    .Abouttopcontant {
        padding-left: 0px;
        margin-top: 100px;
    }
    .leftsideBottamimage {
        img {
            width: 100%;
        }
    }
}

.carousel.slide {
    .carousel-item {
        .carousel-caption {
            right: 8%;
            left: 8%;
        }
    }
    .carousel-inner {
        padding-top: 15%;
    }
}


.Abouttopcontant {
    color: #fff;
    max-width: 555px;
    padding-left: 80px;
    h2 {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 150%;
        letter-spacing: 0.04em;
        color: #FFFFFF;
        span {
            background: linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}
.opacitynone {
    opacity: 0;
}
.AboutsliderImages_main {
    display: block;
    .AboutsliderImages {
        display: inline-block;
        width: 42%;
        margin: 10px 15px;
        opacity: 0.7;
        &:hover {
            opacity: 1;
            // border: 4px solid #FF2F01;
            border-radius: 50%;
        }
        // &:focus {
        //     opacity: 1;
        //     border: 4px solid #FF2F01;
        //     border-radius: 50%;
        // }
        img {
            width: 100%;
        }
    }
    .AboutsliderImages.active {
            opacity: 1;
            border: 4px solid #FF2F01;
            border-radius: 50%;
    }
}

.carousel.slide {
    .carousel-indicators [data-bs-target] {
        width: 8px;
        height: 8px;
        border-radius: 10px;
        margin-right: 8px;
        margin-left: 8px;
        cursor: pointer;
        background-color: #123752;
        opacity: 1;
        border: 0px none;
    }
    .carousel-indicators .active {
        opacity: 1;
        background-color: #FF2F01;
    }
    .carousel-control-prev {
        display: none;
    }
    .carousel-control-next {
        display: none;
    }
}


@media (max-width:990px) {
    .Abouttopcontant {
        padding-left: 10px;
        margin-top: 20px;
    }
    .leftside_contant_about .Abouttopcontant {
        margin-top: 40px;
    }
    .leftside_contant_about .leftsideBottamimage img {
        display: none;
    }
    .margintop {
        margin-top: 40px;
    margin-bottom: 40px;
    }
    .AboutsliderImages_main .AboutsliderImages {
        width: 39%;
    }
    .carousel.slide .carousel-inner {
        padding-top: 100%;
    }
    .Abouttopcontant {
        h2 {
            font-size: 30px;
            line-height: 36px;
        }
    }
}
@media (max-width:767px) {
    .AboutsliderImages_main .AboutsliderImages {
        width: 42%;
        margin: 5px;
    }
    .carousel.slide .carousel-inner {
        padding-top: 25%;
    }
    .bgRightLogo:before {
        display: none;
    }
 }