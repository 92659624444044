@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

body, *, h1, h2, h3, h4, h5, h6 {
    font-family: 'Archivo', sans-serif;
}

p {
    font-family: 'Roboto', sans-serif;
}
body {
    overflow-x: hidden !important;
}