
.tokenstream_header {
    padding-top: 40px !important;
    #basic-navbar-nav {
        justify-content: end;
        align-items: center;
    }
    .align-item-center {
        align-items: center;
        .mobileviewshoiw {
            display: none;
        }
    }
    .rightside-space {
        a {
            color: #fff;
            margin: 0px 0px;
            font-size: 24px;
            padding: 10px 16px;
            &:hover {
                color: #FF2F01;
            }
        }
        .btn-orange {
            margin-right: 0px;
            font-size: 20px;
            margin-left: 20px;
            &:hover {
                color: #fff;
            }
        }
    }
}

.btn-orange {
    background: linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%);
    border-radius: 80px;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 700;
    border: 0px none;
    padding: 0px 34px;
    height: 64px;
    line-height: 64px;
    &:hover {
        background: linear-gradient(80.04deg, #FF2E00 5.55%, #FAB013 90.36%);
    }
}
.btn_orange_border {
    background: linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 1px solid #FF2F01;
    border-radius: 50px;
    padding: 0px 34px;
    height: 64px;
    line-height: 64px;
    font-size: 20px;
    font-weight: 700;
    &:hover {
        border: 1px solid #fff;
        background: linear-gradient(80.04deg, #fff 5.55%, #fff 90.36%);
        color: #FF2F01;
        -webkit-background-clip:#FF2F01;
        -webkit-text-fill-color: #FF2F01;
    }
}
.btn-orange.btn.btn-primary, a.btn.btn-orange {
    background: linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%);
    border-radius: 80px;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 700;
    border: 0px none;
    padding: 0px 34px;
    height: 64px;
    line-height: 64px;
    &:hover {
        background: linear-gradient(80.04deg, #FF2E00 5.55%, #FAB013 90.36%);
    }
}
.btn_orange_border.btn.btn-primary  {
    background: linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 1px solid #FF2F01;
    border-radius: 50px;
    padding: 0px 34px;
    height: 64px;
    line-height: 64px;
    font-size: 20px;
    font-weight: 700;
    &:hover {
        // border: 1px solid #fff;
        background: linear-gradient(80.04deg, #081c2a 5.55%, #081c2a 90.36%);
        -webkit-text-fill-color: #FF2F01;
        color: #FF2F01;
    }
}
// Header CSS end


// Center Heading CSS
.centerheading_main {
    margin-top: 40px;
    h2 {
        font-weight: 800;
        font-size: 36px;
        line-height: 36px;
        letter-spacing: 0.04em;
        color: #FFFFFF;
        span {
            background: linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            // color: #FF2E00;
        }
    }
    p {
        color: #708EA4;
        font-weight: 400;
        font-size: 18px;
        margin-top: 15px;
        line-height: 27px;
    }
    .blue_heading {
        color: #123752;
    }
}

.accordion {
    margin-bottom: 16px;
    font-family: 'Roboto';
    .accordion-button:not(.collapsed) {
        color: #000;
        background-color: transparent;
    }
    .accordion-button:not(.collapsed)::after {
        background-image: url(../Static/Iconarrowdown.svg);
    }
    .accordion-header {
        button.accordion-button {
            font-size: 24px;
            line-height: 34px;
            padding: 20px 20px 20px 30px;
            box-shadow: none;
            color: #123752;
            font-weight: 500;
        }
    }
    .accordion-item:last-of-type .accordion-button.collapsed {
        border-radius: 12px;
    }
    .accordion-item:last-of-type .accordion-collapse {
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    .accordion-item:first-of-type {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border-radius: 12px;
    }
    .accordion-item {
        background-color: #fff;
        border: 1px solid rgba(1, 20, 34, 0.3);
    }
    .accordion-button::after {
        transform: rotate(0deg);
        background-image: url(../Static/Iconarrowdown.svg);
        background-size: 14px;
    background-position: center;
    }
    .accordion-button:not(.collapsed)::after {
        transform: rotate(180deg);
      
    }
    .accordion-body {
        padding: 0px 30px 30px 30px;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #708EA4;
    font-family: 'Roboto';
    }
}

// table CSS

.assetname_item_img {
    display: flex;
    align-items: center;
    p {
        margin: 0px 0px 0px 15px;
    }
}
.strategy_none {
    background: #486E88;
    border-radius: 50px;
    text-align: center;
    width: 70px;
    height: 30px;
    line-height: 30px;
}

// Tabs CSS 
.Indicesdetails_tabs {
    .nav-tabs {
        border-bottom: 1px solid #123752;
        li {
            .nav-link {
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: rgba(255, 255, 255, 0.54);
                padding: 20px 25px;
                &:hover {
                    border: 1px solid #032137;
                    border-bottom: 1px solid #123752;
                }
            }
            .nav-link.active {
                background-color: transparent;
                border: 0px none;
                border-bottom: 2px solid #FF2F01;
            }
        }
    }
    .tab-content {
        padding: 60px 100px;
        p {
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: rgba(255, 255, 255, 0.84);
        }
        ul {
            padding:0px;
            list-style: none;
            h3 {
                font-family: 'Roboto';
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: rgba(255, 255, 255, 0.84);
                margin-top: 30px;
                margin-bottom: 20px;
            }
            li {
                p {
                    svg {
                        color: #27AE60;
                        font-size: 25px;
                        margin-right: 12px;
                    }
                }
            }
        }
    }
}
// Tabs CSS END

//  Footer CSS 

.Footer_main {
    .footer_logo_main {
        padding: 70px 0px 50px 0px;
        border-bottom: 1px solid rgba(72, 110, 136, 0.3);
        margin-bottom: 30px;
    }
    ul {
        padding-left: 0px;
        h3 {
            font-family: 'Roboto';
            font-weight: 700;
            font-size: 24px;
            line-height: 52px;
            text-transform: capitalize;
            color: #FFFFFF;
        }
        li {
            list-style: none;
            a {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 36px;
                color: #FFFFFF;
                text-decoration: none;
                transition: all 0.3s;
                svg {
                    margin-right: 10px;
                    font-size: 22px;
                }
                &:hover {
                    color: #FF2E00;
                }
            }
        }
    }
    .Footercopyright {
        border-top: 1px solid rgba(72, 110, 136, 0.3);
        margin-top: 20px;
        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 52px;
            text-transform: capitalize;
            color: #FFFFFF;
            opacity: 0.6;
            margin-bottom: 0px;
            padding: 15px 0px;
        }
    }
}


// Footer CSS
.footer_subscrption {
    background: #123752;
    display: flex;
    padding: 60px;
    border-radius: 10px;
    align-items: center;
    background-image: url(../Static/footeremail-bg.png);
    background-repeat: no-repeat;
    background-position: -10% 0%;
    margin-top: 75px;
    h2 {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 150%;
        color: #FFFFFF;
        flex: 0 0 50%;
        margin-bottom: 0;
    }
    .footer_form_email {
        width: 500px;
        position: relative;
        input.form-control {
            background: #032137;
            border: 0px none;
            height: 60px;
            padding: 0px 15px;
            color: #fff;
        }
        .btn_arrow {
            position: absolute;
            top: 0px;
            right: 0px;
            border: 0px none;
            background: transparent;
            height: 60px;
            padding: 0px 15px;
            svg {
                color: #FF2E00;
            }
        }
    }
}
// Footer CSS end



@media (max-width:990px) {
    .Footer_main .socialFooterlinks ul li {
        max-width: 100px;
        text-align: left;
        margin: 0px auto;
    }
    .accordion .accordion-body {
        padding: 0px 20px 30px 20px;
    }

    .tokenstream_header {
        padding-top: 25px !important;
            padding: 0px 15px;
    }
    // .bg_blue {
    //     .container {
    //         .tokenstream_header {
    //             padding: 0px 0px; 
    //         }
    //     }
    // }
    body.fixmenu {
        overflow: hidden;
        // padding-right: 17px;
        // background-color: #f1f1f1;
    }
    .bg_blue .btndetails {
        padding: 30px 0px 0px 0px;
    }
    .footer_subscrption {
        padding: 20px;
        flex-wrap: wrap;
        h2 {
            flex: 0 0 100%;
            font-size: 24px;
            margin-bottom: 15px;
        }
        .footer_form_email {
            .mb-3 {
                margin-bottom: 0px !important;
            }
        }
    }
    .tokenstream_header button.navbar-toggler:focus {
        outline: none !important;
        box-shadow: none !important;
    }
    
    .tokenstream_header {
        padding-top: 20px;
        .container {
            padding: 0px;
        }
        .navbar-collapse {
            position: fixed;
            right: 0px;
            left: 0px;
            bottom: 0px;
            top: 100px;
            background-color: #011422;
            overflow: hidden;
            z-index: 999999999;
            transition: all 0.2s;
            height: 0 !important;
        }
        .navbar-collapse.show {
            height: 100% !important;
        }
        #basic-navbar-nav {
            margin-top: -20px;
            .rightside-space {
                a{
                    margin: 10px 10px;
                    font-size: 24px;
                    text-decoration: none;
                    span.mobileviewshoiw {
                        font-size: 18px;
                        margin-left: 10px;
                    }
                }
                .btn-orange {
                    padding: 0px 20px;
                    font-size: 18px;
                    display: inline-block;
                    height: 64px;
                    line-height: 64px;
                    min-width: 220px;
                    margin-top: 30px;
                }
            }
            .align-item-center {
                display: flex;
                text-align: center;
                margin-top: 25%;
                margin-right: 17px;
                .mobileviewshoiw {
                    display: inline-block;
                }
            }
        }
        #basic-navbar-nav.collapse:not(.show) {
            display: none;
        }
        .navbar-brand {
            width: 78%;
            margin-right: 0px;
            img {
                width: 100%;
                max-width: 280px;
            }
        }
        button.navbar-toggler {
            padding-right: 0px;
            span {
                background-image: url(../Static/menuToggle.svg);
                background-image: none !important;
                background-size: 24px;
                background-size: 24px;
                height: 2px;
                border-top: 0px none;
                width: 35px;
                position: relative;
                transition: all 0.3s;
                &:before {
                    content: "";
                    width: 35px;
                    height: 2px;
                    background-color: #fff;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    transform: rotate(45deg);
                }
                &:after {
                    content: "";
                    width: 35px;
                    height: 2px;
                    background-color: #fff;
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    transform: rotate(-45deg);
                }
            }

        }
        button.navbar-toggler.collapsed span:before {
            transform: rotate(0deg);
            top: -15px;
        }
        button.navbar-toggler.collapsed span:after {
            transform: rotate(0deg);
            bottom: -14px;
        }
        button.navbar-toggler.collapsed span {
            border-top: 2px solid #fff;
        }
        .navbar-light .navbar-toggler:focus {
            outline: none;
            box-shadow: none;
        }
        
        
    }
    .centerheading_main {
        padding-right: 15px;
        padding-left: 15px;
    }
}
@media (max-width:767px) {
    .Indicesdetails_tabs {
        .tab-content {
            ul {
                li {
                    p {
                        display: flex;
                        svg {
                            width: 45px;
                            min-width: 30px;
                            font-size: 25px;
                            margin-right: 8px;
                        }
                    }
                }
            }
        }
    }

    .centerheading_main {
        h2 {
            font-size: 30px;
            line-height: 36px;
        }
    }
    .accordion .accordion-header button.accordion-button {
        font-size: 18px;
        line-height: 26px;
        padding: 20px 20px 20px 20px;
    }
    .Footer_main {
        ul {
            text-align: center;
            margin-bottom: 30px;
            h3 {
                font-size: 20px;
                line-height: 32px;
            }
        }
        .Footercopyright p {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
        }
    }
    .tokenstream_header #basic-navbar-nav .rightside-space a {
        min-width: 144px;
        text-align: left;
    } 
    .tokenstream_header #basic-navbar-nav .rightside-space .btn-orange {
        text-align:center;
    }
    
}

@media (max-width:467px) {
    .btndetails {
        .btn_orange_border.btn.btn-primary {
            width: 85%;
            margin-bottom: 15px;
        }
        .btn-orange.btn.btn-primary {
            min-width: 85%;
        }
    }
    
}

@media (max-width:620px) {
    .Indicesdetails_tabs .nav-tabs li .nav-link {
        padding: 15px 22px;
        font-size: 16px;
    }
}
@media (max-width:520px) {
    .Indicesdetails_tabs .nav-tabs li .nav-link {
        padding: 15px 15px;
        font-size: 14px;
    }
}

@media (max-width:420px) {
    .Indicesdetails_tabs .nav-tabs li .nav-link {
        padding: 15px 10px;
        font-size: 14px;
    }
}
@media (max-width:380px) {
    .Indicesdetails_tabs .nav-tabs li .nav-link {
        padding: 15px 9px;
        font-size: 12px;
    }
}