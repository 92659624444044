
.bg_blue {
    background-color: #011422;
    color: #fff;
    clip-path: polygon(0 0, 100% 0, 100% 96%, 0% 100%);
}
.bg_blue_middle {
    background-color: #011422;
    color: #fff;
}

.topbanner_image {
    margin-top: 40px;
    img {
        width: 100%;
    }
}

.Imageshadow_main {
    position: relative;
    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1;
        background: rgba(72, 110, 136, 0.3);
        filter: blur(100px);
        // background-image: url(../../Static/ImageShadow.svg);
        // background-size: contain;
    }
}

.btndetails {
    padding: 40px 0px 0px 0px;
    button {
        margin: 0px 20px;
    }
}

.img_responsive {
    img {
        width: 100%;
    }
}
.Imagebg_Shadow {
    position: relative;
    &:before {
        content: "";
        filter: blur(100px);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        z-index: 0;
        background: rgba(72, 110, 136, 0.5);
    }
    img {
        z-index: 9;
        position: relative;
    }
}

.main_leftright_space {
    padding: 90px 0px;
}
.paddingRightnone {
    padding-right: 0px !important;
}
.get_access_main {
    padding-bottom: 150px;
    padding-top: 150px;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        right: 0px;
        width: 42%;
        height: 100%;
        background-image: url(../../Static/circelIcon_getaccess.svg);
        background-repeat: no-repeat;
        background-position: 100% 50%;
        top: 0;
        bottom: 0;
        background-size: 100%;
        z-index: -1;
        animation: mover .9s infinite alternate;
    }
    &:before {
        content: "";
        position: absolute;
        left: 0px;
        width: 40%;
        height: 100%;
        background-image: url(../../Static/bg_getaccess_logo.svg);
        background-repeat: no-repeat;
        background-position: bottom left;
        bottom: 50px;
        background-size: 50%;
        animation: mover 1.9s infinite alternate;
        z-index: -1;
    }
    
    .col-6 {
        padding-right: 0px;
        padding-left: 0px;
    }
    .leftside_getaccess {
        max-width: 555px;
        margin-left: auto;
        padding-right: 40px;
        h2 {
            font-family: 'Archivo';
            font-weight: 800;
            font-size: 36px;
            line-height: 150%;
            letter-spacing: 0.04em;
            color: #FFFFFF;
        }
        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 32px;
            color: #708EA4;
        }
        h4 {
            font-family: 'Archivo';
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            color: #FFFFFF;
            margin-top: 40px;
            svg {
                width: 18px;
                margin-right: 15px;
            }
        }
    }
}

.leftsidecontant_mainbutton {
    .btn-orange.btn.btn-primary {
        margin-top: 15px;
    }
    .btn-havealook {
        margin-top: 15px;
    }
}
.subheadingpoints_main {
    h4 {
        font-family: 'Archivo';
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #FFFFFF;
        margin-top: 40px;
        svg {
            width: 18px;
            margin-right: 15px;
        }
    }
    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #708EA4;
    }
}

.DiscoverAPI_main {
    max-width: 80%;
    margin: 0px auto;
    h4 {
        font-family: 'Archivo';
        font-weight: 500;
        font-size: 36px;
        line-height: 36px;
        color: #FFFFFF;
        margin-top: 40px;
        span {
            background: linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    
}

.leftside_contant {
    h2 {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        letter-spacing: 0.04em;
        color: #FFFFFF;
        span {
            background: linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
        }
    }
    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #708EA4;
    }
}

.align-item-center {
    align-items: center;
}

// Multichain circel CSS

.MultichainIndices_main {
    position: relative;
    padding-top: 10px;
    padding-bottom: 50px;
    background-image: url(../../Static/bg_multichain.png);
    background-size: cover;
    background-repeat: no-repeat;
}
.Multichaincircel_main {
    max-width: 800px;
    margin: 80px auto;
    .blueborder {
        width: 630px;
        height: 630px;
        margin: 0px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &:before {
            content: "";
            border: 2px dashed rgba(1, 20, 34, 0.6);
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            animation: spinAround 80s linear infinite;
        }
        .icon_first {
            position: absolute;
            right: 80px;
            top: 20px;
            &:after {
                position: absolute;
                content: "";
                width: 12px;
                height: 12px;
                top: -5px;
                border-radius: 50%;
                background: linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%);
                border: 2px solid #ffccd9;
                right: -4px;
                z-index: 9;
            }
        }
        .icon_second {
            position: absolute;
            left: 80px;
            bottom: 20px;
            background-color: #fff;
            border-radius: 50%;
        }
        .pointspace {
            position: absolute;
            left: 110px;
            width: 28px;
            height: 28px;
            top: 51px;
            border-radius: 50%;
            background: linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%);
            border: 4px solid #ffccd9;
        }
        .circelcontant_top {
            position: absolute;
            width: 250px;
            background-color: #fff;
            border: 1px dashed #000;
            border-radius: 80px;
            padding: 20px 25px;
            top: -65px;
            right: -285px;
            &:before {
                content: "";
                position: absolute;
                width: 85px;
                left: -86px;
                top: 47px;
                border-top: 1px dashed #000;
            }
            &:after {
                content: "";
                position: absolute;
                width: 50px;
                border-top: 1px dashed #000;
                transform: rotate(130deg);
                left: -128px;
                top: 66px;
            }
            p {
                font-family: 'Roboto';
                margin: 0px;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }
    .lightorange {
        width: 482px;
        height: 482px;
        margin: 0px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &:before {
            content: "";
            border: 3.36px dashed rgba(255, 47, 1, 0.2);
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            animation: spinAroundReturn 80s linear infinite;
        }
        .icon_lightorange1 {
            position: absolute;
            left: -44px;
            top: 196px;
            &:after {
                position: absolute;
                content: "";
                width: 12px;
                height: 12px;
                bottom: 36px;
                border-radius: 50%;
                background: linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%);
                border: 2px solid #ffccd9;
                left: -17px;
                z-index: 9;
            }
        }
        .icon_lightorange2 {
            position: absolute;
            right: 40px;
            bottom: 20px;
            &:after {
                position: absolute;
                content: "";
                width: 12px;
                height: 12px;
                bottom: -3px;
                border-radius: 50%;
                background: linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%);
                border: 2px solid #ffccd9;
                right: -12px;
                z-index: 9;
            }
        }
        .circelcontant_top {
            position: absolute;
            width: 250px;
            background-color: #fff;
            border: 1px dashed #000;
            border-radius: 80px;
            padding: 20px 25px;
            top: auto;
            right: -345px;
            bottom: -50px;
            &:before {
                content: "";
                position: absolute;
                width: 85px;
                left: -86px;
                top: 47px;
                border-top: 1px dashed #000;
            }
            &:after {
                content: "";
                position: absolute;
                width: 50px;
                border-top: 1px dashed #000;
                transform: rotate(210deg);
                left: -133px;
                top: 35px;
            }
            p {
                font-family: 'Roboto';
                margin: 0px;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: rgba(0, 0, 0, 0.6);
            }
        }
        .circelcontant_top.circelcontant_center {
            left: -384px;
            bottom: auto;
            top: 196px;
            &:before {
                right: -85px;
                left:auto;
            }
            &:after {

            }
        }
    }
    .lightblueborder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 311px;
        height: 311px;
        border-radius: 50%;
        margin: 0px auto;
        position: relative;
        &:before {
            content: "";
            border: 1.36413px dashed rgba(3, 33, 55, 0.3);
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            animation: spinAround 80s linear infinite;
        }
        .icon_lightblueborder1 {
            position: absolute;
            left: 50px;
            top: -30px;
        }
        .pointspace {
            position: absolute;
            right: 30px;
            left: auto;
            width: 22px;
            height: 22px;
            top: 40px;
            border-radius: 50%;
            background: linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%);
            border: 3px solid #ffccd9;
        }
        .pointspace2 {
            position: absolute;
            left: 28px;
            width: 20px;
            height: 20px;
            bottom: 42px;
            border-radius: 50%;
            background: linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%);
            border: 3px solid #ffccd9;
        }
    }
    .darkorangeborder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 180px;
        border-radius: 50%;
        position: relative;
        &:before {
            content: "";
            border: 3px dashed #fe3d02;
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            animation: spinAroundReturn 80s linear infinite;
        }
        .pointspace {
            position: absolute;
            left: 5px;
            width: 14px;
            height: 14px;
            top: 40px;
            border-radius: 50%;
            background: #FF265A;
            border: 2px solid #ffccd9;
        }
    }
    .centerlogo_multichain {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


// FAQ 
.faq_main {
    padding: 60px 0px;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        width: 35px;
        height: 35px;
        right: 100px;
        bottom: 50px;
        border-radius: 50%;
        border: 5px solid #486E88;
        z-index: -1;
        left: 0;
        margin: 0px auto;
        opacity: 0.4;
        -webkit-animation: mover 9s infinite alternate;
        animation: mover .9s infinite alternate;
    }
}
.faq_accordianspace {
    max-width: 960px;
    margin: 50px auto;
    position: relative;
    &:before{
        content: "";
        position: absolute;
        width: 50px;
        height: 50px;
        left: -20px;
        top: -10px;
        border-radius: 50%;
        border: 5px solid #FFBA49;
        z-index: -1;
        -webkit-animation: mover 9s infinite alternate;
        animation: mover .9s infinite alternate;
    }
    &:after {
        content: "";
        position: absolute;
        width: 35px;
        height: 35px;
        right: -20px;
        top: 60px;
        border-radius: 50%;
        border: 5px solid #486E88;
        z-index: -1;
        
    }
    .accordion {
        margin-bottom: 35px;
    }
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}


// partner CSS
.Partners_main {
    padding-top: 60px;
    background-image: url(../../Static/partnerBG.svg);
    background-position: 50% 115%;
    background-repeat: no-repeat;
    background-size: 95%;
}
.partnericon_bg {
    display: flex;
    margin-top: 50px;
    img {
        width: 100%;
    }
    .PartnerIcon {
        transform: scale(1);
        transition: all 0.3s;
        &:hover {
            transform: scale(1.1);
        }
    }
}

// Get start bar
.Getstartbar_main {
    padding: 70px 0px;
    background: #032137;
    display: flex;
    margin-top: -80px;
    z-index: 99999999;
    position: relative;
    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 52px;
        color: #FFFFFF;
    }
}

// CodeEditor 
.codeeditior_main {
    width: 80% !important;
    margin: 30px auto 80px !important;
    position: relative;
    &:after {
        content: "";
        filter: blur(100px);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        z-index: 0;
        background: rgba(72, 110, 136, 0.5);
    }
    .codeeditior_main_body {
        z-index: 999;
        position: relative;
        .neviagationDots {
            position: absolute;
            left: 14px;
            top: 10px;
            span {
                position: relative;
                width: 10px;
                height: 10px;
                background-color: #ccc;
                display: inline-block;
                margin-right: 6px;
                border-radius: 50%;
            }
            span.redDot {
                background-color: #FF4A4A;
            }
            span.OrangeDot {
                background-color: #FFB83D;
            }
            span.GreenDot {
                background-color: #00C543;
            }
        }
    }
    .nav-tabs {
        background: #191919;
        margin: 0px !important;
        border-bottom: 0px solid #000;
        padding-left: 70px;
        .nav-item {
            .nav-link {
                color: rgba(255, 255, 255, 0.5);
                &:hover {
                    border-color: transparent;
                }
            }
            .nav-link.active {
                background-color: transparent;
                border-color: transparent;
                border-bottom: 2px solid #96CBFE!important;
                color: #96CBFE !important;
            }
        }
    }
    .tab-content {
        background: #222222;
        border: 1px solid #000000;
        padding: 20px;
        padding: 20px 20px 120px 20px;
        .ex_showprecode {
            position: absolute;
            right: -100px;
            bottom: -70px;
            .ex_showprecode_body {
                max-width: 510px;
                background: #191919;
                border: 1px dashed #505050;
                border-radius: 13px;
                padding: 10px;
                margin-bottom: 20px;
                -webkit-animation: moverx 9s infinite alternate;
                animation: moverx 1.2s infinite alternate;
                pre.code code::before  {
                    content: " ";
                    padding-left: 0px;
                }
            }
        }
    }

      pre.code {
        white-space: pre-wrap;
      }
      pre.code::before {
        counter-reset: listing;
      }
      pre.code code {
        counter-increment: listing;
      }
      pre.code code::before {
        content: counter(listing) ". ";
        display: inline-block;
        height: 22px;
        margin-left: auto;
        text-align: right;
        color: rgba(255, 255, 255, 0.4);
      }
      pre.code {
        white-space: pre-wrap;
      }
      
      pre.code::before {
        counter-reset: listing;
      }
      
      pre.code code {
        counter-increment: listing;
        text-align: left;
        float: left;
        clear: left;
      }
      pre.code {
        code {
            animation: typing 4s steps(50, end);
            overflow: hidden;
            white-space: nowrap; 
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
        }
        code:nth-child(2) {
            -webkit-animation-delay: 3s;
            animation-delay: 3s;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
        }
        code:nth-child(3) {
            -webkit-animation-delay: 4s;
            animation-delay: 4s;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
        }
        .lightblue {
            color: #96CBFE;
        }
        .darkblue {
            color:#4A90E2;
        }
        .lightgreen {
            color: #A8FF60;
        }
      }
      
      
      pre.code code::before {
        content: counter(listing) ". ";
        display: inline-block;
        height: 22px;
        padding-left: 20px;
        margin-left: auto;
        text-align: right;
      }
}

.css-typing {
    padding-left: 10px;
    p {
        counter-increment: listing;
        text-align: left;
        float: left;
        clear: left;
        padding-left: 30px;
        margin-bottom: 5px;
        &:before {
            content: counter(listing) ". ";
            display: inline-block;
            height: 22px;
            padding-left: 20px;
            margin-left: auto;
            text-align: right;
            position: absolute;
            left: 12px;
            color: rgba(255, 255, 255, 0.4);
        }
    }
}

.css-typing p {
    // border-right: .15em solid orange;
    font-family: "Courier";
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
  }
  .css-typing p:nth-child(1) {
    width: 10em;
    -webkit-animation: type2 1s steps(40, end);
    animation: type2 1s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  .css-typing p:nth-child(2) {
    width: 1px;
    opacity: 0;
    -webkit-animation: type2 0s steps(40, end);
    animation: type2 0s steps(40, end);
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing p:nth-child(3) {
    width: 43em;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end);
    animation: type2 2s steps(40, end);
    -webkit-animation-delay: 2s;
    animation-delay: 1.2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  .css-typing p:nth-child(4) {
    width: 10px;
    opacity: 0;
    -webkit-animation: type2 1s steps(10, end);
    animation: type2 1s steps(10, end);
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing p:nth-child(5) {
    width: 7em;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end);
    animation: type2 2s steps(40, end);
    -webkit-animation-delay: 2.1s;
    animation-delay: 2.1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing p:nth-child(6) {
    width: 24em;
    opacity: 0;
    -webkit-animation: type2 1s steps(100, end);
    animation: type2 1s steps(100, end);
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing p:nth-child(7) {
    width: 19em;
    opacity: 0;
    -webkit-animation: type3 1s steps(100, end);
    animation: type3 1s steps(100, end);
    -webkit-animation-delay: 6s;
    animation-delay: 6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing p:nth-child(8) {
    width: 10px;
    opacity: 0;
    -webkit-animation: type2 1s steps(10, end);
    animation: type2 1s steps(10, end);
    -webkit-animation-delay:7s;
    animation-delay: 7s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing p:nth-child(9) {
    width: 10px;
    opacity: 0;
    -webkit-animation: type2 1s steps(10, end);
    animation: type2 1s steps(10, end);
    -webkit-animation-delay: 7s;
    animation-delay: 7s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing p:nth-child(10) {
    width: 29em;
    opacity: 0;
    -webkit-animation: type3 1s steps(100, end);
    animation: type3 1s steps(100, end);
    -webkit-animation-delay: 8s;
    animation-delay: 8s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing p:nth-child(11) {
    width: 10px;
    opacity: 0;
    -webkit-animation: type2 1s steps(10, end);
    animation: type2 1s steps(10, end);
    -webkit-animation-delay: 9s;
    animation-delay: 9s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing p:nth-child(12) {
    width: 12em;
    opacity: 0;
    -webkit-animation: type3 1s steps(100, end);
    animation: type3 1s steps(100, end);
    -webkit-animation-delay: 9s;
    animation-delay: 9s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  @keyframes type {
    0% {  width: 0;}
    99.9% { border-right: .15em solid orange; }
    100% {  border: none;}
  }
  
  @-webkit-keyframes type {
    0% {  width: 0; }
    99.9% {  border-right: .15em solid orange; }
    100% { border: none; }
  }
  @keyframes type2 {
    0% {  width: 0; }
    1% {  opacity: 1; }
    99.9% { border-right: .15em solid orange;}
    100% {  opacity: 1;  border: none; }
  }
  
  @-webkit-keyframes type2 {
    0% { width: 0; }
    1% { opacity: 1; }
    99.9% {  border-right: .15em solid orange; }
    100% {  opacity: 1; border: none;}
  }
  
  @keyframes type3 {
    0% {  width: 0; }
    1% {  opacity: 1; }
    99.9% { border-right: .15em solid orange;}
    100% {  opacity: 1;  border: none; }
  }
  
  @-webkit-keyframes type3 {
    0% { width: 0; }
    1% { opacity: 1; }
    99.9% {  border-right: .15em solid orange; }
    100% {  opacity: 1; border: none;}
  }
  
  @keyframes type4 {
    0% { width: 0; }
    1% { opacity: 1; }
    100% {  opacity: 1;}
  }
  
  @-webkit-keyframes type4 {
    0% { width: 0; }
    1% {  opacity: 1; }
    100% { opacity: 1; }
  }
  
 
/* The typing effect */
@keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  @keyframes typing2 {
    from { width: 0 }
    to { width: 100% }
  }

  
  

@-webkit-keyframes moverx {
    0% { transform: translateX(0); }
    100% { transform: translateX(-10px); }
}
@keyframes moverx {
    0% { transform: translateX(0); }
    100% { transform: translateX(-10px); }
}


@-webkit-keyframes spinAround {
    from {
        -webkit-transform: rotate(0deg)
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-moz-keyframes spinAround {
    from {
        -moz-transform: rotate(0deg)
    }
    to {
        -moz-transform: rotate(360deg);
    }
}
@keyframes spinAround {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes spinAroundReturn {
    from {
        transform: rotate(360deg);
        
    }
    to {
        transform: rotate(0deg)
    }
}

@media (max-width:990px) {
    .faq_accordianspace {
        margin: 25px auto;
         .accordion {
            margin-bottom: 15px;
        }
    }

    .btndetails {
        padding: 60px 0px 0px 0px;
        button {
            margin: 5px 20px;
        }
    }
    .centerheading_main {
        margin-top: 40px;
    }

    .bg_blue_middle {
        .main_leftright_space {
            .leftsidecontant_mainbutton {
                .btn.btn-orange {
                    margin-bottom: 40px;
                    margin-top: 20px;
                }
            }
        }
    }

    .codeeditior_main {
        width: 98% !important;
        margin: 30px auto 40px !important;
        position: relative;
    }

    .main_leftright_space .leftside_contant {
        margin-bottom: 30px;
    }
    .leftside_contant {
        h2 {
            font-size: 30px;
            line-height: 36px;
        }
    }
    .subheadingpoints_main {
        h4 {
            font-size: 20px;
            line-height: 28px;
        }
        p {
            font-size: 16px;
            line-height: 28px;
        }
    }
    .DiscoverAPI_main {
        h4 {
            font-size: 30px;
            line-height: 36px;
        }
    }
}

@media (max-width:767px) {
    .codeeditior_main {
        width: 100%;
        margin: 30px auto 40px;
        
        .nav-tabs {
            padding-left: 0px;
            padding-top: 30px;
            .nav-item {
                .nav-link {
                    font-size: 14px;
                }
            }
        }
        .tab-content {
            padding: 20px 20px 20px 20px;
            .ex_showprecode {
                right: 0px;
                bottom: 0px;
                left: 0px;
                position: relative;
                .ex_showprecode_body {
                    width: 100%;
                    animation:none;
                }
            }
        }
    }
    .main_leftright_space {
        padding: 60px 15px;
    }
    .faq_main {
        .centerheading_main {
            margin-top: 0px;
            h2 {
                font-size: 26px;
                line-height: 32px;
            }
        }
        .faq_accordianspace:before {
            display: none;
        }
        .faq_accordianspace:after{
            display: none;
        }
    }
    .Partners_main {
        .centerheading_main {
            margin-top: 0px;
        }
    }
    .Getstartbar_main {
        padding: 50px 0px;
        margin-top: 0px;
        h3 {
            font-size: 26px;
            line-height: 33px;
            margin-bottom: 30px;
        }
    }
    .get_access_main {
        padding-bottom: 90px;
        padding-top: 70px;
        .leftside_getaccess {
            padding-right: 0px;
            margin-bottom: 40px;
            h2 {
                font-size: 26px;
                line-height: 40px;
            }
            h4 {
                font-size: 20px;    
            }
        }
    }
    .MultichainIndices_main {
        overflow: hidden;
    }
}

@media (max-width:990px) {
    .Multichaincircel_main {
        max-width: 800px;
        margin: 120px auto 120px;
        .blueborder {
            .circelcontant_top {
                right: 50px;
                padding: 10px 25px;
                top: -95px;
                &:after {
                    top: 94px;
                    right: 52px;
                    left: auto;
                    transform: rotate(90deg);
                    width: 41px;
                }
                &:before {
                    display: none;
                }
            }
        }
        .lightorange  {
            .circelcontant_top {
                right: 0px;
                padding: 10px 25px;
                top: auto;
                bottom: -130px;
            }
        }
        
    }
    .Multichaincircel_main .lightorange .circelcontant_top:after {
        top: -43px;
        width: 75px;
        right: 42px;
    }
    .Multichaincircel_main .lightorange .circelcontant_top.circelcontant_center, 
    .Multichaincircel_main .blueborder .icon_first:after, 
    .Multichaincircel_main .lightorange .icon_lightorange2:after, 
    .Multichaincircel_main .lightorange .icon_lightorange1:after {
        display: none;
    }
}

@media (max-width:670px) {
    .Multichaincircel_main {
        .blueborder {
            width: 420px;
            height: 420px;
            .pointspace {
                left: 48px;
            }
            .icon_first {
                right: 10px;
                top: 20px;
                &:after {
                    display: none;
                }
                img {
                    width: 60%;
                }
            }
            .icon_second {
                left: 30px;
                bottom: 4px;
                width: 65px;
                text-align: center;
                img {
                    width: 80%;
                }
            }
            .circelcontant_top {
                right: 0px;
                padding: 10px 25px;
                top: -95px;
                &:after {
                    top: 94px;
                    right: 52px;
                    left: auto;
                    transform: rotate(90deg);
                    width: 41px;
                }
                &:before {
                    display: none;
                }
            }
        }
        .lightorange {
            width: 350px;
            height: 350px;
            .icon_lightorange1 {
                left: -25px;
                top: 154px;
                &:after {
                    display: none;
                }
                img {
                    width: 60%;
                }  
             }
            .icon_lightorange2 {
                right: -15px;
                bottom: 30px;
                :after {
                    right: 57px;
                    bottom: -13px;
                }
                img {
                    width: 60%;
                }
            }
            .icon_lightblueborder1 {
                img {
                    width: 60%;
                }
            }
            .circelcontant_top { 
                right: 0px;
                padding: 10px 25px;
                top: auto;
                bottom: -130px;
                &:after {
                    top: -43px;
                    width: 85px;
                    right: 2px;
                }
                
            }
        }
        .lightblueborder {
            width: 280px;
            height: 280px;
            .pointspace {
                left: 239px; 
            }
            .icon_lightblueborder1 {
                top: -10px;
            }
        }
        .darkorangeborder {
            width: 210px;
            height: 210px;
            .pointspace {
                display: none;
            }
        }
        .centerlogo_multichain {
            img {
                max-width: 120px;
            }
        }
    }
    .Multichaincircel_main .lightorange .icon_lightorange2:after {
        display: none;
    }
}
@media (max-width:450px) {
    .Multichaincircel_main {
        .blueborder {
            width: 370px;
            height: 370px;
            .pointspace {
                left: 31px; 
            }
            .icon_first {
                top: 10px;
            }
            .circelcontant_top {
                &:after {
                    top: 90px;
                    right: 55px;
                    left: auto;
                    transform: rotate(90deg);
                    width: 33px;
                }
                
            }
        }
        .lightorange {
            width: 310px;
            height: 310px;
            .icon_lightorange1 {
                top: 128px;
            }
            .circelcontant_top {
                &:after {
                    top: -38px;
                    right: 9px;
                    left: auto;
                    transform: rotate(90deg);
                    width: 71px;
                }
            }
            .icon_lightorange2 {
                bottom: 20px;
            }
        }
        .lightblueborder {
            width: 240px;
            height: 240px;
            .pointspace {
                left: 206px;
            }
            .pointspace2 {
                left: 12px;
            }
            .icon_lightblueborder1 {
                top: -17px;
            }
            .icon_lightorange2 {
                bottom: 20px;
            }
        }
        .darkorangeborder {
            width: 170px;
            height: 170px;
        }
        .centerlogo_multichain {
            img {
                max-width: 100px;
            }
        }
    }
}
@media (max-width:400px) {
    .Multichaincircel_main {
        .blueborder {
            width: 300px;
            height: 300px;
            .pointspace {
                left: 12px;
            }
            .icon_first {
                right: 10px;
                top: -10px;
                img {
                    width: 60%;
                }
            }
            .icon_second {
                left: 30px;
                bottom: 4px;
                width: 65px;
                text-align: center;
                img {
                    width: 80%;
                }
            }
            .circelcontant_top {
                right: 0px;
                padding: 10px 25px;
                top: -95px;
                &:after {
                    top: 80px;
                    right: 65px;
                    left: auto;
                    transform: rotate(90deg);
                    width: 11px;
                }
                &:before {
                    display: none;
                }
            }
        }
        .lightorange {
            width: 235px;
            height: 235px;
            .icon_lightorange1 {
                    left: -25px;
                    top: 95px;
                img {
                    width: 60%;
                }
            }
            .icon_lightorange2 {
                right: -15px;
                bottom: 0px;
                :after {
                    right: 57px;
                    bottom: -13px;
                }
                img {
                    width: 60%;
                }
            }
            .icon_lightblueborder1 {
                img {
                    width: 60%;
                }
            }
            .circelcontant_top { 
                right: 0px;
                padding: 10px 25px;
                top: auto;
                bottom: -130px;
                &:after {
                    top: -27px;
                    width: 51px;
                    right: 22px;
                }
                
            }
            .pointspace {
                left: 136px;
                top: 23px;
            }
        }
        .lightblueborder {
            width: 165px;
            height: 165px;
            .pointspace2 {
                left: 10px;
                bottom: 20px;
            }
            .icon_lightblueborder1 {
                top: -27px;
            }
        }
        .darkorangeborder {
            width: 105px;
            height: 105px;
        }
        .centerlogo_multichain {
            img {
                max-width: 70px;
            }
        }
    }
}

@media (max-width:420px) {
.codeeditior_main {
    .nav-tabs .nav-item .nav-link {
        font-size: 13px;
        padding: 16px 6px;
    }
}
}
