.bg_detailIndices {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        left: 0px;
        width: 40%;
        height: 100%;
        background-image: url(../../Static/bg_getaccess_logo.svg);
        background-repeat: no-repeat;
        bottom: -40px;
        background-size: 300px;
        z-index: 0;
    }
    &:after {
        content: "";
        position: absolute;
        right: 0px;
        width: 42%;
        height: 100%;
        background-image: url(../../Static/bg-detailindices.svg);
        background-repeat: no-repeat;
        top: 0;
        background-position: 100% 0%;
    }
}
.Indices_main {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        left: 0px;
        width: 40%;
        height: 100%;
        background-image: url(../../Static/bg_getaccess_logo.svg);
        background-repeat: no-repeat;
        bottom: -100px;
        background-size: 300px;
        z-index: 0;
    }
    &:after {
        content: "";
        position: absolute;
        right: 0px;
        width: 42%;
        height: 100%;
        background-image: url(../../Static/bg-detailindices.svg);
        background-repeat: no-repeat;
        top: 0;
        background-position: 0% 0%;
        z-index: 0;
    }
    .Indices_heading {
        margin-top: 75px;
        margin-bottom: 40px;
        h3 {
            font-family: 'Archivo';
            font-weight: 800;
            font-size: 36px;
            line-height: 150%;
            letter-spacing: 0.04em;
            color: #FFFFFF;
        }
    }
    .IndicesList_main {
        background: rgba(34, 34, 34, 0.7);
        border-radius: 10px;
        margin-bottom: 24px;
        padding: 20px;
        color: #fff;
        display: flex;
        justify-content: space-between;
        border: 1px solid rgba(34, 34, 34, 0.7);
        z-index: 999;
        position: relative;
        &:hover {
            background: #032137;
            border: 1px solid #ff2e00;
        }
        .Indices_left {
            display: flex;
            align-items: center;
            .IndicesList_image {
                margin-right: 20px;
            }
            .IndicesList_middle {
                h3 {
                    font-family: 'Archivo';
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 24px;
                    color: #FFFFFF;
                    margin-bottom: 5px;
                }
                .IndicesList_Amount {
                    font-family: 'Archivo';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    color: rgba(255, 255, 255, 0.84);
                    margin-right: 15px;
                }
                .greencolor_indices {
                    color: #6FCF97;
                    svg {
                        margin-top: -2px;
                    }
                }
                .redcolor_indices {
                    color: #EB5757;
                    .redcolor_indices svg {
                        margin-top: -1px;
                    }
                }
                p {
                    margin-bottom: 0px;
                    margin-top: 15px;
                }
            }
        }
        .IndicesList_inception {
            h5 {
                font-family: 'Archivo';
                font-weight: 600;
                font-size: 24px;
                line-height: 150%;
                color: #FF2F01;
            }
        }
    }
    .IndicesLinks {
        a {
            text-decoration: none;
        }
    }
}

.IndicesDetailspage {
    margin-top: 50px;
    z-index: 999;
    position: relative;
}
.IndicesDetails_main {
    background: #032137;
    border-radius: 10px;
    margin-bottom: 30px;
    color: #fff;
    .Graph_indice_main {
        padding: 20px;
    }
    .Tabletree_indice_main {
        h3 {
            font-family: 'Archivo';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 26px;
            color: #FFFFFF;
            padding-top: 15px;
            padding-left: 15px;
        }
        .react-bootstrap-table {
            thead {
                tr {
                    th {
                        font-family: 'Roboto';
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        color: rgba(255, 255, 255, 0.54);
                        text-align: left;
                        padding: 20px 15px;
                        border: 0px none;
                    }
                }
            }
            tbody {
                border-top: 1px solid #123752;
                tr {
                    td {
                        color: rgba(255, 255, 255, 0.84);
                        text-align: left;
                        padding: 10px 15px;
                        border: 0px none;
                        border-bottom: 1px solid #123752;
                        border-color: #123752;
                        vertical-align: middle;
                    }
                }
                tr:last-child td {
                    border-bottom: 0px none;
                }
            }
        }
    }
    .IndicesDetails_header {
        justify-content: space-between;
        padding: 20px;
    }
    .IndicesDetails_left {
        display: flex;
        align-items: center;
        .IndicesDetails_image {
            margin-right: 20px;
        }
        .IndicesDetails_contant {
            color: #fff;
            h3 {
                margin-bottom: 5px;
            }
            p {
                margin-bottom: 0px;
            }
            .IndicesDetails_scoreamount {
                margin-top: 10px;
                .IndicesList_Amount {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 150%;
                    color: rgba(255, 255, 255, 0.84);
                    margin-right: 15px;
                }
                .greentext_amount {
                    color: #6FCF97;
                    svg {
                        margin-top: -2px;
                    }
                }
            }
        }
    }
    .IndicesDetails_buysell {
        text-align: right;
        .btn-orange.btn-primary {
            background: linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%);
            border-radius: 80px;
            color: #FFFFFF;
            font-weight: 700;
            font-size: 16px;
            border: 0px none;
            padding: 0px 16px;
            height: 40px;
            line-height: 40px;
        }
    }
    .IndicesDetails_tabs {
        margin-top: 40px;
        ul {
            padding: 0;
            list-style: none;
            margin: 0px;
            li {
                color: #fff;
                display: inline-block;
                margin-left: 30px;
                p {
                    margin: 0px;
                }
                p:first-child {
                    color: rgba(255, 255, 255, 0.54);
                    font-size: 16px;
                }
            }
            li:first-child {
                color: #FF2F01;
                p:first-child {
                    color: #FF2F01;
                }
            }
        }
    }
}


@media (max-width:990px) {
    .Indicesdetails_tabs .tab-content {
        padding: 20px 20px;
    }
    // .Indicesdetails_tabs .nav-tabs li .nav-link {
    //     padding: 15px 15px;
    // }
    .IndicesDetails_tabs {
        .nav-tabs {
            ul {
                li {
                    .nav-link {
                        padding: 15px 15px;
                    }
                }
            }
        }
    }
    .Indices_main {
        .Indices_heading {
            margin-top: 40px;
            margin-bottom: 40px;
            h3 {
                font-size: 26px;
                line-height: 150%;
            }
        }
        .IndicesList_main {
            flex-wrap: wrap;
            .Indices_left {
                flex-wrap: wrap;
                .IndicesList_image {
                    margin-bottom: 30px;
                }
                .IndicesList_middle h3 {
                    margin-bottom: 15px;
                }
            }
            .IndicesList_inception {
                margin-top: 20px;
                p {
                    margin-bottom: 0px;
                }
            }
        }
    }
    .IndicesDetails_main { 
        .IndicesDetails_header {
            flex-wrap: wrap;
        }
        .react-bootstrap-table {
            display: block;
            width: 100%;
            overflow-x: auto;
            .table {
                min-width: 640px;
            }
        }
        .IndicesDetails_left {
            flex-wrap: wrap;
            .IndicesDetails_image {
                margin-bottom: 20px;
            }
            .IndicesDetails_contant {
                width: 100%;
            }
        }
        .IndicesDetails_right {
            margin-top: 20px;
            .IndicesDetails_buysell {
                text-align: left;
            }
            .IndicesDetails_tabs {
                ul {
                    li {
                        display: inline-block;
                        margin-left: 0px;
                        margin-bottom: 18px;
                        width: 40%;
                    }
                }
                .nav-tabs {
                    ul {
                        li {
                            .nav-link {
                                padding: 15px 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}